import React from 'react';
import classnames from 'classnames';
import { ILLUSTRATION_TYPES } from './IllustrationList';

import styles from './ChangeColorEasily.module.scss';
import containerStyles from '../Container.module.scss';
import ChangeColorsEasilyItem from './ChangeColorsEasilyItem';

const DATA = [
  {
    [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/illustration-1.png',
    [ILLUSTRATION_TYPES.RETINA]: '/illustrations/illustration-1@2x.png',
  },
  {
    [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/illustration-8.png',
    [ILLUSTRATION_TYPES.RETINA]: '/illustrations/illustration-8@2x.png',
  },
  {
    [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/illustration-9.png',
    [ILLUSTRATION_TYPES.RETINA]: '/illustrations/illustration-9@2x.png',
  },
  {
    [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/illustration-4.png',
    [ILLUSTRATION_TYPES.RETINA]: '/illustrations/illustration-4@2x.png',
  },
  {
    [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/illustration-3.png',
    [ILLUSTRATION_TYPES.RETINA]: '/illustrations/illustration-3@2x.png',
  },
  {
    [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/illustration-2.png',
    [ILLUSTRATION_TYPES.RETINA]: '/illustrations/illustration-2@2x.png',
  },
  {
    [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/illustration-7.png',
    [ILLUSTRATION_TYPES.RETINA]: '/illustrations/illustration-7@2x.png',
  },
  {
    [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/illustration-6.png',
    [ILLUSTRATION_TYPES.RETINA]: '/illustrations/illustration-6@2x.png',
  },
  {
    [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/illustration-5.png',
    [ILLUSTRATION_TYPES.RETINA]: '/illustrations/illustration-5@2x.png',
  },
];

const ChangeColorEasily = () => (
  <section
    className={classnames(
      styles.changeColorEasily,
      containerStyles.container,
      containerStyles.containerWithBackground
    )}>
    <header className={styles.changeColorEasily__heading}>
      <h2 className={styles.changeColorEasily__title}>Change colors easily</h2>
      <p className={styles.changeColorEasily__content}>
        You can change all colors at once with just a few clicks in Sketch,
        Figma and Adobe XD
      </p>
    </header>
    <ul className={styles.changeColorEasilyList}>
      {DATA.map((illustration) => (
        <ChangeColorsEasilyItem
          key={illustration[ILLUSTRATION_TYPES.DEFAULT]}
          illustration={illustration}
        />
      ))}
    </ul>
  </section>
);

export default ChangeColorEasily;
