import React, { useState } from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import isSubpage from '../utils/isSubpage';
import Intro from '../components/IndexPage/Intro';
import Demo from '../components/IndexPage/Demo';
import ChangeColorEasily from '../components/IndexPage/ChangeColorsEasily';
import Examples from '../components/IndexPage/Examples';
import Features from '../components/IndexPage/Features';
import Presentation from '../components/IndexPage/Presentation';

const IndexPage = ({ location }) => {
  const [isHeaderSticky, setSticky] = useState(null);
  const [isInitialized, setInitialized] = useState(false);

  const handleStickyChange = (isSticky) => {
    if (!isInitialized && isSticky !== isHeaderSticky) {
      setInitialized(true);
    }

    setSticky(isSticky);
  };

  return (
    <Layout
      isSubpage={isSubpage(location.pathname)}
      onStickyChange={handleStickyChange}>
      <Seo title="Illustrations set for projects" />
      <Intro isHeaderSticky={isHeaderSticky} isInitialized={isInitialized} />
      <Demo />
      <ChangeColorEasily />
      <Examples />
      <Features />
      <Presentation />
    </Layout>
  );
};

export default IndexPage;
