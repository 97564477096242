import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { upperFirst } from 'lodash';

import styles from './Offer.module.scss';

const BUTTON_STYLES = {
  OUTLINE: 'outline',
  FULL: 'full',
};

const Button = ({
  children,
  className,
  isDisabled,
  offerLink,
  onClick,
  style,
}) => {
  const handleClick = (event) => {
    if (event && onClick) {
      event.preventDefault();
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <a
      className={classnames(className, styles.button, {
        [styles.buttonIsDisabled]: isDisabled,
        [styles[`button${upperFirst(style)}Style`]]:
          style !== Button.defaultProps.style,
      })}
      href={offerLink}
      onClick={handleClick}>
      {children}
    </a>
  );
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  offerLink: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.oneOf(Object.values(BUTTON_STYLES)),
};

Button.defaultProps = {
  className: '',
  offerLink: '#',
  onClick: null,
  style: BUTTON_STYLES.OUTLINE,
};

export default Button;
