import React from 'react';
import classnames from 'classnames';

import styles from './Features.module.scss';
import containerStyles from '../Container.module.scss';

const DATA = [
  {
    content:
      'It contains 78 illustrations in solid style and 78 in outline style.',
    title: '156 hand-drawn illustrations',
  },
  {
    content:
      'Change colors with just a few clicks to fit the color palette of your product.',
    title: 'Easy to change colors',
  },
  {
    content:
      'Modify colors, style and the look & feel of the illustration to fit your brand and product.',
    title: '100% editable vector files',
  },
  {
    content:
      'Perfect for digital products, printouts, marketing materials and more.',
    title: 'Ideal for your products',
  },
  {
    content:
      'All new illustrations added to this package in the future are completely free.',
    title: 'Free lifetime updates',
  },
  {
    content:
      'You can use Whoooa! illustrations in both personal and commercial projects.',
    title: 'Personal & commercial license',
  },
  {
    content:
      'One license allows the use of illustrations in unlimited number of projects.',
    title: '1 license = multiple projects',
  },
  {
    content: 'Suitable for many products and industries.',
    title: '26 most popular subjects',
  },
  {
    content:
      'Manual editing illustrations to change colors is generally very time-consuming.',
    title: 'Save time',
  },
];

const Features = () => (
  <section className={classnames(styles.features, containerStyles.container)}>
    <header className={styles.features__heading}>
      <h2 className={styles.features__title}>Who is this product for?</h2>
      <p className={styles.features__content}>
        If you want to bring your product to the next level without spending
        extra money, and which you can customize within seconds instead of hours
        then this is a project for you!
      </p>
    </header>
    <ul className={styles.featuresList}>
      {DATA.map(({ content, title }) => (
        <li className={styles.featuresList__item} key={`${title}-${content}`}>
          <h3 className={styles.featuresList__title}>{title}</h3>
          <p className={styles.featuresList__content}>{content}</p>
        </li>
      ))}
    </ul>
  </section>
);

export default Features;
