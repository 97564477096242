import React, { PureComponent } from 'react';
import classnames from 'classnames';

import SketchIllustration from '../../images/sketch-logo.inline.svg';
import FigmaIllustration from '../../images/figma-logo.inline.svg';
import AdobeXdIllustration from '../../images/xd-logo.inline.svg';

import styles from './Presentation.module.scss';
import containerStyles from '../Container.module.scss';

const WISTIA_IDS = [
  {
    id: 'tqc5b2w2tu',
    content: () => (
      <>
        <SketchIllustration
          height={16}
          className={classnames(
            styles.presentationNav__illustration,
            styles.presentationNav__illustrationIsSketch
          )}
        />
        Sketch
      </>
    ),
    name: 'sketch',
  },
  {
    id: 'q0e3t1unfr',
    content: () => (
      <>
        <FigmaIllustration
          height={16}
          className={classnames(
            styles.presentationNav__illustration,
            styles.presentationNav__illustrationIsFigma
          )}
        />
        Figma
      </>
    ),
    name: 'figma',
  },
  {
    id: 'kdl3dp7sn3',
    content: () => (
      <>
        <AdobeXdIllustration
          className={classnames(
            styles.presentationNav__illustration,
            styles.presentationNav__illustrationIsAdobeXd
          )}
        />
        Adobe XD
      </>
    ),
    name: 'xd',
  },
];

class Presentation extends PureComponent {
  componentDidMount() {
    initWistia(WISTIA_IDS);
  }

  state = {
    activeId: 0,
  };

  handleButtonClick = (activeId) => {
    this.setState({ activeId });
  };

  getStyles = () => {
    const { activeId } = this.state;

    if (!activeId) {
      return { transform: 'translate3d(0, 0, 0)' };
    }

    return {
      transform: `translate3d(-${(activeId / WISTIA_IDS.length) * 100}%, 0, 0)`,
    };
  };

  render() {
    const { activeId } = this.state;

    return (
      <section
        className={classnames(styles.presentation, containerStyles.container)}>
        <nav className={styles.presentationNav}>
          <ul className={styles.presentationNav__list}>
            {WISTIA_IDS.map(({ content: Content, name }, index) => (
              <PresentationButton
                id={index}
                isActive={index === activeId}
                key={name}
                onClick={this.handleButtonClick}>
                <Content />
              </PresentationButton>
            ))}
          </ul>
        </nav>
        <div className={styles.presentation__videosWrapper}>
          <div className={styles.presentation__videosAnimation}>
            <div
              className={styles.presentation__videos}
              style={this.getStyles()}>
              {WISTIA_IDS.map(({ id }, index) => (
                <WistiaPlayer id={id} index={index} key={id} />
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const PresentationButton = ({ children, id, isActive, onClick }) => {
  const handleClick = () => {
    onClick(id);
  };
  return (
    <li className={styles.presentationNav__item}>
      <button
        className={classnames(styles.presentationNav__button, {
          [styles.presentationNav__buttonIsActive]: isActive,
        })}
        onClick={handleClick}
        type="button">
        {children}
      </button>
    </li>
  );
};

const WistiaPlayer = ({ id, index, isActive }) => {
  return (
    <div
      className={classnames(styles.presentation__video, {
        [styles.presentation__videoIsActive]: isActive,
      })}>
      <div
        className={classnames(
          'wistia_embed',
          `wistia_async_${id}`,
          'videoFoam=true'
        )}
      />
    </div>
  );
};

const initWistia = (ids) => {
  const initScript = () => {
    const wistiaScript = document.createElement('script');
    wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    wistiaScript.async = true;
    document.body.appendChild(wistiaScript);
  };

  const initVideos = () =>
    ids.forEach((id) => {
      const wistiaVideo = document.createElement('script');
      wistiaVideo.src = `https://fast.wistia.com/embed/medias/${id}.jsonp`;
      wistiaVideo.async = true;

      document.body.appendChild(wistiaVideo);
    });

  initScript();
  initVideos();
};

export default Presentation;
