import React from 'react';
import classnames from 'classnames';
import { upperFirst } from 'lodash';
import handleViewport from 'react-in-viewport';

import { ILLUSTRATION_TYPES } from './IllustrationList';
import styles from './Examples.module.scss';
import containerStyles from '../Container.module.scss';

const EXAMPLE_TYPES = {
  PHONE: 'phone',
  TABLET: 'tablet',
  PC: 'pc',
  SHIRT: 'shirt',
};

const DATA = [
  {
    image: {
      [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/example-1.png',
      [ILLUSTRATION_TYPES.RETINA]: '/illustrations/example-1@2x.png',
    },
    type: EXAMPLE_TYPES.PHONE,
  },
  {
    image: {
      [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/example-2.png',
      [ILLUSTRATION_TYPES.RETINA]: '/illustrations/example-2@2x.png',
    },
    type: EXAMPLE_TYPES.PHONE,
  },
  {
    image: {
      [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/example-3.png',
      [ILLUSTRATION_TYPES.RETINA]: '/illustrations/example-3@2x.png',
    },
    type: EXAMPLE_TYPES.PHONE,
  },
  {
    image: {
      [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/example-4.png',
      [ILLUSTRATION_TYPES.RETINA]: '/illustrations/example-4@2x.png',
    },
    type: EXAMPLE_TYPES.PHONE,
  },
  {
    image: {
      [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/example-5.png',
      [ILLUSTRATION_TYPES.RETINA]: '/illustrations/example-5@2x.png',
    },
    type: EXAMPLE_TYPES.TABLET,
  },
  {
    image: {
      [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/example-6.png',
      [ILLUSTRATION_TYPES.RETINA]: '/illustrations/example-6@2x.png',
    },
    type: EXAMPLE_TYPES.PC,
  },
  {
    image: {
      [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/example-7.png',
      [ILLUSTRATION_TYPES.RETINA]: '/illustrations/example-7@2x.png',
    },
    type: EXAMPLE_TYPES.PC,
  },
  {
    image: {
      [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/example-8.png',
      [ILLUSTRATION_TYPES.RETINA]: '/illustrations/example-8@2x.png',
    },
    type: EXAMPLE_TYPES.PC,
  },
  {
    image: {
      [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/example-9.png',
      [ILLUSTRATION_TYPES.RETINA]: '/illustrations/example-9@2x.png',
    },
    type: EXAMPLE_TYPES.SHIRT,
    hideOnMobile: true,
  },
  {
    image: {
      [ILLUSTRATION_TYPES.DEFAULT]: '/illustrations/example-10.png',
      [ILLUSTRATION_TYPES.RETINA]: '/illustrations/example-10@2x.png',
    },
    type: EXAMPLE_TYPES.TABLET,
    hideOnMobile: true,
  },
];

const Examples = () => (
  <section className={classnames(styles.examples, containerStyles.container)}>
    <header className={styles.examples__heading}>
      <h2 className={styles.examples__title}>
        Ideal for web, mobile apps, websites, marketing materials or printouts{' '}
      </h2>
      <p className={styles.examples__content}>
        Use it for personal and commercial projects, templates for sale, work
        presentations or another fancy Dribbble shot
      </p>
    </header>
    <ul className={styles.examplesList}>
      {DATA.map(({ hideOnMobile, image, type }) => (
        <ExamplesItem
          hideOnMobile={hideOnMobile}
          image={image}
          key={image.default}
          type={type}
        />
      ))}
    </ul>
  </section>
);

const ExamplesItem = handleViewport(
  ({ forwardedRef, enterCount, hideOnMobile, image, inViewport, type }) => (
    <li
      className={classnames(
        styles.examplesList__item,
        styles[`examplesList__item${upperFirst(type)}Type`],
        {
          [styles.examplesList__itemIsVisible]: inViewport || enterCount > 0,
        }
      )}
      data-hide-on-mobile={hideOnMobile}
      key={image[ILLUSTRATION_TYPES.DEFAULT]}
      ref={forwardedRef}>
      <img
        alt="Example illustration"
        className={classnames(
          styles.examplesList__illustration,
          styles[`examplesList__illustration${upperFirst(type)}Type`]
        )}
        src={image[ILLUSTRATION_TYPES.DEFAULT]}
        srcSet={`${image[ILLUSTRATION_TYPES.DEFAULT]} 1x, ${
          image[ILLUSTRATION_TYPES.RETINA]
        } 2x`}
      />
    </li>
  ),
  { rootMargin: '-30%' }
);

export default Examples;
