import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import handleViewport from 'react-in-viewport';
import styles from './StickyDetector.module.scss';

const StickyDetector = ({ className, forwardedRef }) => (
  <span
    className={classnames(className, styles.stickyDetector)}
    ref={forwardedRef}
  />
);

StickyDetector.propTypes = {
  className: PropTypes.string,
};

StickyDetector.defaultProps = {
  className: '',
};

export default handleViewport(StickyDetector);
