import React from 'react';
import handleViewport from 'react-in-viewport';
import classnames from 'classnames';

import { ILLUSTRATION_TYPES } from './IllustrationList';
import styles from './ChangeColorEasily.module.scss';

const ChangeColorsEasilyItem = ({
  enterCount,
  forwardedRef,
  illustration,
  inViewport,
}) => (
  <li
    className={classnames(styles.changeColorEasilyList__itemWrapper, {
      [styles.changeColorEasilyList__itemWrapperIsVisible]:
        inViewport || enterCount > 0,
    })}
    ref={forwardedRef}>
    <div
      className={classnames(styles.changeColorEasilyList__item, {
        [styles.changeColorEasilyList__itemIsVisible]:
          inViewport || enterCount > 0,
      })}>
      <img
        alt="Illustration"
        className={styles.changeColorEasilyList__illustration}
        src={illustration[ILLUSTRATION_TYPES.DEFAULT]}
        srcSet={`${illustration[ILLUSTRATION_TYPES.DEFAULT]} 1x, ${
          illustration[ILLUSTRATION_TYPES.RETINA]
        } 2x`}
      />
    </div>
  </li>
);

export default handleViewport(
  (props) => <ChangeColorsEasilyItem {...props} />,
  { rootMargin: '-20%' }
);
