import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import handleViewport from 'react-in-viewport';

import BuyBanner from './BuyBanner';
import styles from './Intro.module.scss';
import containerStyles from '../Container.module.scss';
import IntroIllustration from '../../images/intro-illustration.svg';

const Intro = ({
  enterCount,
  forwardedRef,
  isHeaderSticky,
  inViewport,
  isInitialized,
  onScrollToFooter,
}) => {
  const handleButtonClick = () => {
    onScrollToFooter();
  };

  return (
    <section
      className={classnames(styles.intro, containerStyles.container)}
      ref={forwardedRef}>
      <header
        className={classnames(styles.intro__heading, {
          [styles.intro__headingIsVisible]: enterCount > 0 || inViewport,
        })}>
        <h1 className={styles.intro__title}>
          <span className={styles.intro__titleIsPrimary}>156</span> customizable
          illustrations for your projects
        </h1>
        <p className={styles.intro__content}>
          Use fantastic, handmade illustrations with <br />
          easily changeable colors and different styles
        </p>
      </header>
      <div
        className={classnames(styles.introIllustrationWrapper, {
          [styles.introIllustrationWrapperIsVisible]:
            enterCount > 0 || inViewport,
        })}>
        <img
          alt="Illustration"
          className={styles.introIllustration}
          loading="lazy"
          src={IntroIllustration}
          width={653}
        />
      </div>
      {isInitialized && (
        <BuyBanner
          isHidden={isHeaderSticky}
          onButtonClick={handleButtonClick}
        />
      )}
    </section>
  );
};

Intro.propTypes = {
  isHeaderSticky: PropTypes.bool,
  isInitialized: PropTypes.bool.isRequired,
  onBuyBannerButtonClick: PropTypes.func,
};

Intro.defaultProps = {
  isHeaderSticky: false,
  onBuyBannerButtonClick: null,
};

export default handleViewport((props) => <Intro {...props} />);
