import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import handleViewport from 'react-in-viewport';

import styles from './Demo.module.scss';
import containerStyles from '../Container.module.scss';
import IllustrationList from './IllustrationList';
import StickyDetector from '../StickyDetector';

class Demo extends PureComponent {
  state = {
    isChecked: false,
  };

  handleSwitcherChange = () => {
    this.setState((state) => ({
      isChecked: !state.isChecked,
    }));
  };

  render() {
    const { isChecked } = this.state;

    return (
      <div className={styles.demoWrapper}>
        <section
          className={classnames(
            containerStyles.container,
            containerStyles.containerWithBackground,
            styles.demo
          )}>
          <Switcher
            isChecked={isChecked}
            onChange={this.handleSwitcherChange}
          />
          <IllustrationList isChecked={isChecked} />
        </section>
      </div>
    );
  }
}

const Switcher = handleViewport(
  ({ enterCount, forwardedRef, inViewport, isChecked, onChange }) => {
    const [isSticky, setSticky] = useState(false);

    const onStickyIn = () => {
      setSticky(true);
    };

    const onStickyOut = () => {
      setSticky(false);
    };

    return (
      <>
        <StickyDetector
          onEnterViewport={onStickyOut}
          onLeaveViewport={onStickyIn}
        />
        <div
          className={classnames(styles.switcherWrapper, {
            [styles.switcherWrapperIsSticky]: isSticky,
            [styles.switcherWrapperIsVisible]: inViewport || enterCount > 0,
          })}
          ref={forwardedRef}>
          <button
            className={classnames(styles.switcher, {
              [styles.switcherIsChecked]: isChecked,
            })}
            onClick={onChange}
            type="button">
            <SwitcherLabel isActive={!isChecked}>Outline</SwitcherLabel>
            <SwitcherLabel isActive={isChecked}>Solid</SwitcherLabel>
          </button>
        </div>
      </>
    );
  }
);

Switcher.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SwitcherLabel = ({ children, isActive }) => (
  <span
    className={classnames(styles.switcher__label, {
      [styles.switcher__labelIsActive]: isActive,
    })}>
    {children}
  </span>
);

SwitcherLabel.propTypes = {
  children: PropTypes.any.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default Demo;
