import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { PRODUCT_LINK } from '../../enums/links';
import Button from '../Button';
import styles from './BuyBanner.module.scss';
import SketchLogo from '../../images/sketch-logo.inline.svg';
import FigmaLogo from '../../images/figma-logo.inline.svg';
import XdLogo from '../../images/xd-logo.inline.svg';

const TOOLS = {
  SKETCH: 'Sketch',
  FIGMA: 'Figma',
  XD: 'Xd',
  AI: 'AI',
  EPS: 'EPS',
  SVG: 'SVG',
  PNG: 'PNG',
  JPG: 'JPG',
};

const TOOLS_ICONS = {
  [TOOLS.SKETCH]: SketchLogo,
  [TOOLS.FIGMA]: FigmaLogo,
  [TOOLS.XD]: XdLogo,
};

const hasIcon = (tool) => {
  return !!TOOLS_ICONS[tool];
};

const BuyBanner = ({ isHidden, onButtonClick }) => {
  return (
    <aside
      className={classnames(styles.buyBanner, {
        [styles.buyBannerIsHidden]: isHidden,
      })}>
      <div className={styles.buyBannerFiles}>
        <span className={styles.buyBanner__label}>Available for:</span>
        <ul className={styles.buyBannerFiles__list}>
          {Object.values(TOOLS).map((tool) => (
            <File hasLogo={hasIcon(tool)} key={tool}>
              {tool}
            </File>
          ))}
        </ul>
      </div>
      <Button
        className={styles.buyBanner__button}
        offerLink={PRODUCT_LINK}
        onClick={onButtonClick}>
        I want this!
      </Button>
      <div className={styles.buyBannerSection}>
        <span className={styles.buyBanner__label}>Compatibile with:</span>
        <p className={styles.buyBanner__content}>
          Sketch, Figma, Adobe XD, Illustrator, Photoshop, Affinity Designer and
          more
        </p>
      </div>
    </aside>
  );
};

BuyBanner.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func,
};

BuyBanner.defaultProps = {
  onButtonClick: null,
};

const File = ({ children, hasLogo }) => {
  const Icon = TOOLS_ICONS[children];

  const getFilesItemClassName = () =>
    classnames(styles.buyBannerFiles__item, {
      [styles.buyBannerFiles__itemIsIconOnly]: hasLogo,
    });

  return (
    <li className={getFilesItemClassName()}>
      {Icon && (
        <Icon
          className={classnames(styles.buyBanner__logo, {
            [styles[`buyBanner__logoIs${children}`]]: Icon,
          })}
        />
      )}
      {children}
    </li>
  );
};

File.propTypes = {
  children: PropTypes.any.isRequired,
};

export default BuyBanner;
